import { Box, Button, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import { ReactComponent as InfoSvg } from "../../../assets/svg/info.svg";
import dayjs from "dayjs";
import { handleUpdateAppointment } from "../../../../helpers/appointment.helper";
import { useCallback, useState } from "react";
import { handleAddHistories } from "../../../../helpers/histories.helper";
import { LoadingButton } from "@mui/lab";
import { handleGetMairieBySlug } from "../../../../helpers/mairie.helper";
import { handleDeleteAppointment } from "../../../../helpers/ants.helper";
import { handleGetAppointments } from "../../../store/usager.store";
require("dayjs/locale/fr");

export const StepOneDeletion = ({
    appointment,
    type = "normal",
    setAppointment,
    setActiveStep,
    refetchData = () => {},
}) => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);

    const handleCancelAppointment = useCallback(async () => {
        setLoading(true);
        let res: any = null;
        let input: any = { _id: appointment._id, status: "CANCELED" };
        res = await handleUpdateAppointment(input);
        if (res?.success) {
            const mairie = await handleGetMairieBySlug(appointment.mairie);
            for (const person of appointment?.concernedPerson) {
                if (person?.ants !== "") {
                    await handleDeleteAppointment({
                        ants: person?.ants,
                        meeting_point: mairie?.name,
                        meeting_point_id: mairie?._id,
                        appointment_date: appointment?.heureRdv?.replace(
                            "T",
                            " "
                        ),
                    });
                }
            }
            const result = await handleAddHistories({
                phoneNumber: appointment.phoneNumber,
                appointmentId: appointment._id,
                status: input?.status,
                mairie: appointment.mairie,
                heureRdv: appointment.heureRdv,
                typeDossier: appointment.typeDossier,
                nombrePersonne: appointment?.nombrePersonne,
                usager: appointment.usager,
            });
            setAppointment(null);
            handleGetAppointments(appointment.email);
            refetchData();
            if (result.success) {
                setSuccess(true);
            }
        }
        setLoading(false);
    }, [appointment, refetchData]);

    return (
        <>
            <Stack
                flexDirection={"row"}
                className="items-center justify-between"
            >
                <p className="title-bold px-5 py-2 mb-0">Annulation globale</p>
                {type !== "modal" && (
                    <Box>
                        <IconButton onClick={() => setAppointment(null)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                )}
            </Stack>
            {!success && appointment ? (
                <>
                    <Stack
                        flexDirection={"row"}
                        gap={2}
                        className="px-5 py-4 w-full border-left-red items-center warning-bloc"
                    >
                        <InfoSvg width={30} height={30} />
                        <Box>
                            <p className="mb-0">
                                Attention votre rendez-vous le{" "}
                                {dayjs(appointment?.heureRdv)
                                    .locale("fr")
                                    .format("DD MMMM YYYY à HH:mm")}{" "}
                                va être annulé, et sera supprimé de votre liste
                                de rendez-vous.
                            </p>
                        </Box>
                    </Stack>
                    <Stack
                        flexDirection={"row"}
                        gap={2}
                        className="px-5 py-4 w-full justify-end"
                    >
                        <LoadingButton
                            loading={loading}
                            disabled={loading}
                            variant="contained"
                            color="primary"
                            className="btn-rdv"
                            onClick={() => handleCancelAppointment()}
                        >
                            Annuler pour la globalité
                        </LoadingButton>
                        {appointment?.concernedPerson?.length > 1 && (
                            <Button
                                variant="contained"
                                color="primary"
                                className="btn-rdv"
                                onClick={() => setActiveStep(2)}
                            >
                                Annuler par personne concerné
                            </Button>
                        )}
                        <Button
                            variant="outlined"
                            color="primary"
                            className="btn-rdv"
                            onClick={() => setAppointment(null)}
                        >
                            Annuler
                        </Button>
                    </Stack>
                </>
            ) : (
                <Stack
                    flexDirection={"row"}
                    gap={2}
                    className="mt-1 px-5 py-2 w-full items-center success-bloc"
                >
                    <TaskAltIcon width={60} height={60} />
                    <Box>
                        <p className="mb-0">
                            Rendez-vous du{" "}
                            <span className="text-red-500">
                                {" "}
                                {dayjs(appointment?.heureRdv)
                                    .locale("fr")
                                    .format("DD MMMM YYYY à HH:mm")}{" "}
                            </span>{" "}
                            a été annulé.
                        </p>
                    </Box>
                </Stack>
            )}
        </>
    );
};
