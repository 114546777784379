import NODE_API from "./axios.helper";
import queryString from "query-string";
import { isEmpty } from "lodash";
import { REACT_APP_TOKEN_COLLECTION, REACT_APP_URL } from "../constant";

export const handleGetStatus = async (
    application_ids,
    meeting_point_id: string
) => {
    const query = queryString.stringify({
        application_ids: [...application_ids],
        meeting_point_id,
    });
    const headers = {
        "Content-Type": "application/json",
        "x-hub-rdv-auth-token": REACT_APP_TOKEN_COLLECTION,
    };
    try {
        if (!meeting_point_id) console.log("API GET STATUS", meeting_point_id);
        const res = await NODE_API.get(
            "/api/ants/status?" + query,
            {},
            headers
        );
        return res;
    } catch (error) {
        console.log(error);
        return {};
    }
};

export const handlePostAppointment = async (appointment) => {
    try {
        let success = true;
        const headers = {
            "Content-Type": "application/json",
            "x-hub-rdv-auth-token": REACT_APP_TOKEN_COLLECTION,
        };
        for (const person of appointment?.concernedPerson) {
            const input = {
                application_id: person?.ants,
                management_url: `${REACT_APP_URL}/gestion/login?ants=${person?.ants}&appointment_id=${appointment?._id}&canceled=true`,
                meeting_point: appointment?.meeting_point,
                meeting_point_id: appointment?.meeting_point_id,
                editor_comment: "",
                appointment_date:
                    appointment?.heureRdv?.replace("T", " ")?.slice(0, 16) +
                    ":00",
            };
            const res = await NODE_API.post(
                "/api/ants/appointments",
                input,
                headers
            );
            if (!input?.meeting_point_id)
                console.log("API POST APPOINTMENT", input);
            if (isEmpty(res)) {
                success = false;
            }
        }
        return success;
    } catch (error) {
        console.log(error);
        return {};
    }
};

export const handleDeleteAppointment = async (appointment) => {
    try {
        let success = true;
        const query = queryString.stringify({
            application_id: appointment?.ants,
            meeting_point: appointment?.meeting_point,
            meeting_point_id: appointment?.meeting_point_id,
            appointment_date:
                appointment?.appointment_date?.slice(0, 16) + ":00",
        });
        const headers = {
            "Content-Type": "application/json",
            "x-hub-rdv-auth-token": REACT_APP_TOKEN_COLLECTION,
        };
        const res = await NODE_API.delete(
            "/api/ants/appointments?" + query,
            headers
        );
        if (!appointment?.meeting_point_id)
            console.log("API DELETE APPOINTMENT", query);
        if (isEmpty(res)) {
            success = false;
        }

        return success;
    } catch (error) {
        console.log(error);
        return {};
    }
};

export const handleAddFileAppointment = async (file) => {
    try {
        let success = true;
        const headers = {
            "Content-Type": "multipart/form-data",
            "x-hub-rdv-auth-token": REACT_APP_TOKEN_COLLECTION,
        };
        const res = await NODE_API.post(
            "/api/ants/add_appointments_from_file",
            {
                file: file,
            },
            headers
        );
        if (isEmpty(res)) {
            success = false;
        }
        return success;
    } catch (error) {
        console.log(error);
        return {};
    }
};
