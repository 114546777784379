import { AppointmentForm } from "@devexpress/dx-react-scheduler-material-ui";
import {
    Alert,
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextareaAutosize,
} from "@mui/material";
import TableHistorique from "../../TableHistorique/TableHistorique";
import EditIcon from "@mui/icons-material/Edit";
import { useCallback, useEffect, useState } from "react";
import { API } from "../../../../constant";
import dayjs from "dayjs";
import axios from "axios";
import { TYPE_STATUS } from "../../../../infrastructure/data/type-status";
import { CustomDialog } from "../../CustomDialog";
import { DetailsUsager } from "../../../../presentation/admin/usager/components/DetailsUsager";
import { handleGetUsagerById } from "../../../../helpers/usager.helper";
import CancelIcon from "@mui/icons-material/Cancel";
import SmsSending from "../../SmsSending";
import EmailSending from "../../EmailSending";
import { CreateAppointmentForm } from "../../ModalCreateAppointment/CreateAppointment";
import { testAccess } from "../../../../infrastructure/utils/acces";
import DeleteRdv from "../../DeleteRdv";
import {
    handleDeleteAppointment,
    handleGetStatus,
    handlePostAppointment,
} from "../../../../helpers/ants.helper";
import { isEmpty } from "lodash";
import { DetailsDuplicate } from "../../DetailsDuplicate";
import { Popup } from "../../Popup/Popup";
import { testStatusAntsNumber } from "../../../../infrastructure/utils/ants";
import { handleGetMairieBySlug } from "../../../../helpers/mairie.helper";
import { useAuthStore } from "../../../store/auth.store";
import {
    refetchListUsagers,
    useMairieStore,
} from "../../../store/mairie.store";
import { updateAppointment } from "../../../../helpers/appointment.helper";
import { getAllAppointment, useAgendaStore } from "../../../store/agenda.store";
require("dayjs/locale/fr");

export const BasicLayout = ({
    onFieldChange,
    appointmentData,
    ...restProps
}: AppointmentForm.BasicLayoutProps) => {
    const [success, setSuccess] = useState<boolean>(false);
    const user = useAuthStore((state) => state.user);
    const mairies = useMairieStore((state) => state.mairies);
    const TYPE_DOSSIERS = useMairieStore((state) => state.TYPE_DOSSIERS);
    const appointments = useAgendaStore((state) => state.appointments);
    const slug = useAgendaStore((state) => state.slug);
    const endDate = dayjs(appointmentData.heureRdv).add(
        appointmentData.dureeRdv,
        "minutes"
    );
    const [appointmentToDelete, setAppointmentToDelete] = useState<any>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [openContact, setOpenContact] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);
    const [openModify, setOpenModify] = useState<boolean>(false);
    const [usager, setUsager] = useState<any>({});
    const [histories, setHistories] = useState<any[]>([]);
    const typeDossier: any = TYPE_DOSSIERS.find(
        (d) => d.key === appointmentData.type
    );
    const [status, setStatus] = useState<string>(appointmentData.status);
    const [text, setText] = useState<string>("");
    const [ui, setUi] = useState({
        createAppointment: false,
    });
    const [alert, setAlert] = useState<any>({
        open: false,
        message:
            "Désolé, le créneau horaire sélectionné vient d'être réservé par un autre utilisateur.",
    });
    const [duplicates, setDuplicates] = useState<any>({});
    const [openDuplicate, setOpenDuplicate] = useState<boolean>(false);
    const [componentContact, setComponentContact] = useState<any>(
        <SmsSending usager={appointmentData} mairie={mairies[0]} />
    );
    const getAllHistories = async (appointment) => {
        const res = (
            await axios.post(API + "/histories/searchBy", {
                // phoneNumber: appointment?.phoneNumber,
                "metadata.usagerId": appointment?.usager?._id,
                "metadata.mairie": appointment.mairie,
            })
        )?.data;
        if (res?.data) {
            setHistories(res?.data);
        }
    };

    useEffect(() => {
        getAllHistories(appointmentData);
        const updated = appointments.find(
            (value) => value._id === appointmentData._id
        );
        Object.assign(appointmentData, updated);
    }, [appointmentData]);

    const handleChangeStatus = (e) => {
        setStatus(e.target.value);
    };

    const handleCloseModalModify = () => {
        setOpenModify(false);
    };

    const handleCloseModal = () => {
        setOpen(false);
    };

    const handleOpenModalContact = (type) => {
        if (type === "sms")
            setComponentContact(
                <SmsSending usager={appointmentData} mairie={mairies[0]} />
            );
        if (type === "email")
            setComponentContact(<EmailSending usager={appointmentData} />);

        setOpenContact(true);
    };

    const handleCloseModalContact = () => {
        setOpenContact(false);
    };

    const handleCloseModalDelete = () => {
        setOpenDelete(false);
    };

    const handleChangeText = (e) => {
        setText(e.target.value);
    };

    const handleUpdateAppointmentData = useCallback(async () => {
        const res: any = await updateAppointment({
            _id: appointmentData._id,
            mairie: appointmentData.mairie,
            typeDossier: appointmentData.typeDossier,
            heureRdv: appointmentData.heureRdv,
            nombrePersonne: appointmentData.nombrePersonne,
            status,
            comment: text,
            agent: user._id,
            usager: appointmentData.usager,
        });
        const mairie = await handleGetMairieBySlug(appointmentData.mairie);
        if (appointmentData?.concernedPerson[0]?.ants !== "") {
            for (const person of appointmentData?.concernedPerson) {
                await handleDeleteAppointment({
                    ants: person?.ants,
                    meeting_point: mairie?.name,
                    meeting_point_id: mairie?._id,
                    appointment_date: appointmentData?.heureRdv?.replace(
                        "T",
                        " "
                    ),
                });
            }
        }
        if (res?._id) {
            getAllAppointment();
        }
    }, [appointmentData, status, text]);

    const handleEditUsager = async () => {
        // const resultUsager = await handleGetUsager({
        //     phoneNumber: appointmentData.phoneNumber,
        //     email: appointmentData.email,
        //     mairie: mairies[0]._id
        // });
        const resultUsager = await handleGetUsagerById(
            appointmentData.usager?._id
        );

        if (resultUsager) {
            setUsager(resultUsager);
            setOpen(true);
        }
    };

    useEffect(() => {
        setTimeout(() => {
            setSuccess(false);
        }, 10000);
    }, [success]);

    useEffect(() => {
        if (!isEmpty(duplicates)) {
            setOpenDuplicate(true);
        }
    }, [duplicates]);

    const handleCloseModalDuplicate = () => {
        setOpenDuplicate(false);
    };

    const handleCloseAlert = () => {
        setAlert((prevState) => ({ ...prevState, open: false }));
    };

    const handleSubmitForm = useCallback(
        async (data) => {
            let success = true;
            setUi((prev) => ({ ...prev, createAppointment: true }));
            const concernedPerson = data.concernedPerson.map((person) => {
                return {
                    ...person,
                    // birthday: dayjs(person.birthday, 'DD/MM/YYYY')
                };
            });
            const appointment = {
                firstName: data.firstName,
                lastName: data.lastName,
                phoneNumber: data.phoneNumber,
                heureRdv: dayjs(data.heureRdv)
                    .utc()
                    .local()
                    .format()
                    .split("+")[0],
                dureeRdv: (data.nombrePersonne || 1) * data.duration,
                typeDossier: data.typeDossier,
                email: data.email !== "" ? data.email : null,
                status: "PENDING",
                mairie: data.mairie,
                room: data.bureau,
                nombrePersonne: data.nombrePersonne,
                concernedPerson: concernedPerson,
                city: data.city.codesPostaux + ", " + data.city.nom,
                agent: user._id,
                isSpecificHour: data?.isSpecificHour,
                comment: data?.comment,
                usager: data?.usager,
            };
            const mairie = await handleGetMairieBySlug(appointment.mairie);
            if (data?.concernedPerson[0]?.ants !== "") {
                for (const person of appointmentData?.concernedPerson) {
                    await handleDeleteAppointment({
                        ants: person?.ants,
                        meeting_point: mairie?.name,
                        meeting_point_id: mairie?._id,
                        appointment_date: appointmentData?.heureRdv?.replace(
                            "T",
                            " "
                        ),
                    });
                }
                const appointmentsId = data?.concernedPerson.reduce(
                    (prev, curr) => [...prev, curr.ants],
                    []
                );
                const resAntsIdsStatus = await handleGetStatus(
                    appointmentsId,
                    mairie?._id
                );
                if (!isEmpty(resAntsIdsStatus)) {
                    Object.keys(resAntsIdsStatus).map(async (key) => {
                        const resAnts = testStatusAntsNumber(
                            resAntsIdsStatus[key]?.status
                        );
                        if (resAnts?.success) {
                            if (resAnts?.error) {
                                const isSame = resAntsIdsStatus[
                                    key
                                ]?.appointments?.find(
                                    (data) =>
                                        data?.meeting_point === mairie?.name &&
                                        data?.appointment_date ===
                                            appointmentData?.heureRdv
                                );
                                if (
                                    !isSame &&
                                    resAntsIdsStatus[key]?.appointments
                                        ?.length > 0
                                ) {
                                    setDuplicates(resAntsIdsStatus);
                                    success = false;
                                }
                            }
                        } else {
                            setAlert({
                                message: resAnts?.error?.replace(
                                    "$num",
                                    `<br> <p>- ${key}</p>`
                                ),
                                open: true,
                            });
                            success = false;
                            return "break";
                        }
                    });
                }
            }

            if (success) {
                const res: any = await updateAppointment({
                    _id: appointmentData?._id,
                    ...appointment,
                });
                if (res?._id) {
                    await handlePostAppointment({
                        ...res,
                        meeting_point: mairie.name,
                        meeting_point_id: mairie._id,
                    });
                    getAllAppointment();
                    handleCloseModalModify();
                }
            }

            setUi((prev) => ({ ...prev, createAppointment: false }));
        },
        [appointmentData, user]
    );

    return (
        <>
            <Grid container marginTop={3} spacing={2}>
                <Grid item xs={3} className="bloc-details">
                    {success && (
                        <Alert severity="success" className="mt-2">
                            Rendez-vous annulé avec succès!
                        </Alert>
                    )}
                    <Stack className="info-usager">
                        <p className="name mb-0">
                            {appointmentData?.usager?.firstName}{" "}
                            <span className="lastname">
                                {appointmentData?.usager?.lastName}
                            </span>{" "}
                        </p>
                        <Box className="mt-2">
                            <p className="phone">
                                Téléphone :
                                <span
                                    className="text-blue"
                                    onClick={() =>
                                        handleOpenModalContact("sms")
                                    }
                                >
                                    {appointmentData?.usager?.phoneNumber}
                                </span>
                            </p>
                            <p className="text-title">
                                Adresse mail :{" "}
                                <span
                                    className="text-blue"
                                    onClick={() =>
                                        handleOpenModalContact("email")
                                    }
                                >
                                    {appointmentData?.usager?.email}
                                </span>{" "}
                            </p>
                            <p className="mb-0">
                                Code postal :{" "}
                                <span className="bold-title">
                                    {appointmentData?.usager?.city}
                                </span>
                            </p>
                        </Box>
                        <div className="edit-usager">
                            <EditIcon onClick={() => handleEditUsager()} />
                        </div>
                    </Stack>
                    <Box marginTop={2}>
                        <p className="text-title">
                            Date et heure :{" "}
                            <span className="text-value">
                                <span className="text-date">
                                    {dayjs(appointmentData?.heureRdv)
                                        .locale("fr-FR")
                                        .format("dddd DD MMMM")}
                                </span>{" "}
                                de{" "}
                                {dayjs(appointmentData.heureRdv)
                                    .locale("fr-FR")
                                    .format("HH:mm")}{" "}
                                {dayjs(endDate)
                                    .locale("fr-FR")
                                    .format("à HH:mm")}
                                <br></br>
                                Durée : {appointmentData?.dureeRdv}mn
                            </span>
                        </p>
                        <p className="text-title">
                            Motif(s) :{" "}
                            <span className="text-value">
                                {typeDossier?.label}
                            </span>
                        </p>
                    </Box>
                    {appointmentData?.comment && (
                        <Box marginTop={1}>
                            <p className="underline">Objet du rendez-vous:</p>
                            <p>{appointmentData?.comment}</p>
                        </Box>
                    )}
                    <Box marginTop={1}>
                        <p className="font-bold uppercase underline mb-1">
                            Personne
                            {appointmentData?.concernedPerson?.length > 1 &&
                                "s"}{" "}
                            concernée
                        </p>
                        {appointmentData?.concernedPerson?.map((person) => (
                            <Box className="flex items-center gap-3">
                                <p>{person.lastName} </p>
                                <p>{person.firstName}</p>
                                {person?.ants && <p>({person?.ants})</p>}
                            </Box>
                        ))}
                    </Box>
                    {testAccess(user, "create_appointment") && (
                        <Stack flexDirection={"row"} gap={2} className="mt-2">
                            {status !== "CANCELED" && (
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    className="btn-rdv btn-reset btn-icon"
                                    startIcon={<CancelIcon />}
                                    onClick={() => {
                                        setOpenDelete(true);
                                        setAppointmentToDelete(appointmentData);
                                    }}
                                >
                                    Annuler le RDV
                                </Button>
                            )}
                            <Button
                                variant="contained"
                                color="secondary"
                                size="small"
                                onClick={() => setOpenModify(true)}
                            >
                                Modifier le RDV
                            </Button>
                        </Stack>
                    )}
                </Grid>
                <Grid item xs={4}>
                    <div className="bloc-input">
                        <InputLabel id="demo-select-small">Statut</InputLabel>
                        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                value={status}
                                label=""
                                onChange={handleChangeStatus}
                            >
                                {TYPE_STATUS.map((status) => (
                                    <MenuItem value={status.key}>
                                        {status.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </div>
                    <div className="bloc-input-textarea">
                        <InputLabel id="demo-select-small">
                            Remarque interne
                        </InputLabel>
                        <TextareaAutosize
                            onChange={handleChangeText}
                            aria-label="minimum height"
                            minRows={6}
                        />
                    </div>
                    {testAccess(user, "create_appointment") && (
                        <div className="btn-bottom">
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={handleUpdateAppointmentData}
                            >
                                Enregistrer
                            </Button>
                        </div>
                    )}
                </Grid>
                <Grid item xs={5}>
                    <div className="table-historique">
                        <p className="title-historique">Historique usager</p>
                        <TableHistorique histories={histories} />
                    </div>
                </Grid>
            </Grid>
            <CustomDialog
                setOpen={setOpen}
                open={open}
                onClose={handleCloseModal}
                component={
                    <DetailsUsager
                        usager={usager}
                        callback={refetchListUsagers}
                    />
                }
            />
            <CustomDialog
                setOpen={setOpenContact}
                open={openContact}
                onClose={handleCloseModalContact}
                component={componentContact}
            />
            <CustomDialog
                setOpen={setOpenDelete}
                open={openDelete}
                onClose={handleCloseModalDelete}
                component={
                    <DeleteRdv
                        appointment={appointmentToDelete}
                        setAppointment={setAppointmentToDelete}
                        type="modal"
                        refetchData={getAllAppointment}
                    />
                }
            />
            <CustomDialog
                setOpen={setOpenModify}
                open={openModify}
                onClose={handleCloseModalModify}
                component={
                    <Box sx={{ padding: "20px", width: "800px" }}>
                        <CreateAppointmentForm
                            loading={ui.createAppointment}
                            setLoading={setUi}
                            slug={mairies[0].slug}
                            slugToAppointment={slug}
                            onConfirm={(event) => handleSubmitForm(event)}
                            appointment={appointmentData as any}
                        />
                    </Box>
                }
            />
            <CustomDialog
                setOpen={setOpenDuplicate}
                open={openDuplicate}
                onClose={handleCloseModalDuplicate}
                component={<DetailsDuplicate duplicates={duplicates} />}
            />
            <Popup
                message={alert?.message}
                type="error"
                open={alert?.open}
                handleClose={handleCloseAlert}
            />
        </>
    );
};
