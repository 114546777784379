import { Box, IconButton, Stack } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import dayjs from "dayjs";
import { useCallback, useState } from "react";
import { handleUpdateAppointment } from "../../../../helpers/appointment.helper";
import { handleAddHistories } from "../../../../helpers/histories.helper";
import { LoadingButton } from "@mui/lab";
import { handleDeleteAppointment } from "../../../../helpers/ants.helper";
import { handleGetMairieBySlug } from "../../../../helpers/mairie.helper";
import { handleGetAppointments } from "../../../store/usager.store";
require("dayjs/locale/fr");

export const StepTwoDeletion = ({
    appointment,
    setAppointment,
    type = "normal",
    setActiveStep,
    refetchData = () => {},
}) => {
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleDeletePerson = useCallback(
        async (data) => {
            setLoading(true);
            let res: any = null;
            let input: any = { _id: appointment._id, status: "CANCELED" };
            const concernedPerson = appointment?.concernedPerson?.filter(
                (person) => person?._id !== data?._id
            );
            if (concernedPerson?.length > 0) {
                const dureeMotif =
                    Number(appointment?.dureeRdv) /
                    Number(appointment?.concernedPerson?.length);
                input = {
                    ...input,
                    concernedPerson: concernedPerson,
                    status: "PENDING",
                    dureeRdv: Number(appointment?.dureeRdv) - (dureeMotif || 0),
                    nombrePersonne: concernedPerson?.length,
                };
            }
            res = await handleUpdateAppointment(input);
            if (res?.success) {
                const result = await handleAddHistories({
                    phoneNumber: appointment.phoneNumber,
                    appointmentId: appointment._id,
                    status: input?.status,
                    mairie: appointment.mairie,
                    heureRdv: appointment.heureRdv,
                    typeDossier: appointment.typeDossier,
                    nombrePersonne:
                        concernedPerson?.length > 0
                            ? concernedPerson?.length
                            : 1,
                    usager: appointment.usager,
                });
                const mairie = await handleGetMairieBySlug(appointment.mairie);
                if (mairie) {
                    await handleDeleteAppointment({
                        ants: data?.ants,
                        meeting_point: mairie?.name,
                        meeting_point_id: mairie?._id,
                        appointment_date: appointment?.heureRdv?.replace(
                            "T",
                            " "
                        ),
                    });
                }
                if (setActiveStep) setActiveStep(1);
                setAppointment(null);
                handleGetAppointments(appointment.email);
                refetchData();
                if (result.success) {
                    setSuccess(true);
                }
            }
            setLoading(false);
        },
        [appointment]
    );

    return (
        <Box>
            <Stack
                flexDirection={"row"}
                className="items-center justify-between"
            >
                <p className="title-bold px-5 mb-0">
                    Annulation de votre rendez-vous
                </p>
                {type !== "modal" && (
                    <Box>
                        <IconButton
                            onClick={() => {
                                setAppointment(null);
                                setActiveStep(1);
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Box>
                )}
            </Stack>
            {!success ? (
                <>
                    <Stack className="px-5 py-2">
                        <p className={"bold"}>Personnes concernées</p>
                        {appointment?.concernedPerson?.map((person) => (
                            <Stack
                                flexDirection={"row"}
                                alignItems={"center"}
                                gap={2}
                                marginTop={1}
                                justifyContent={"space-between"}
                            >
                                <p>
                                    {person?.firstName} {person?.lastName}{" "}
                                    <span>{person?.ants}</span>
                                </p>
                                <LoadingButton
                                    loading={loading}
                                    disabled={loading}
                                    variant="contained"
                                    color="primary"
                                    className="btn-rdv"
                                    onClick={() => handleDeletePerson(person)}
                                >
                                    Annuler
                                </LoadingButton>
                            </Stack>
                        ))}
                    </Stack>
                </>
            ) : (
                <Stack
                    flexDirection={"row"}
                    gap={2}
                    className="mt-1 px-5 py-2 w-full items-center success-bloc"
                >
                    <TaskAltIcon width={60} height={60} />
                    <Box>
                        <p className="mb-0">
                            Rendez-vous du{" "}
                            <span className="text-red-500">
                                {" "}
                                {dayjs(appointment?.heureRdv)
                                    .locale("fr")
                                    .format("DD MMMM YYYY à HH:mm")}{" "}
                            </span>{" "}
                            a été annulé.
                        </p>
                    </Box>
                </Stack>
            )}
        </Box>
    );
};
